import { useState, useEffect, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getCountryList,
  addNewPostalCodes,
  getProvinceByCountryId,
  getSuperCityByProvinceId,
  // getCityBySuperCityId,
  getPostalCodeByCityId,
  getCityByProvinceId
} from "../../../api/locationApi";
import { async } from "q";
import { START_LOADING, STOP_LOADING } from "../../../redux/Slice/loaderSlice";
import { CLEAR_POSTAL_CODES, UPDATE_POSTAL_CODE } from "../../../redux/Slice/locationSlice";
import FormLabelStar from "../../core/FormLabelStar";

const intialForm = {
  "id": 0,
  "codes": [
  ],
  "countryId": null,
  "provinceId": null,
  // "supercityId": null,
  "cityId": null
}

export default function AddNewSuperCity({ isAdded }) {
  const postalCodeList = useSelector((state) => state.location).filteredPostalCode;
  const countryRef = useRef(null);
  const provinceRef = useRef(null);
  const superCityRef = useRef(null);
  const cityRef = useRef(null);

  const [formData, setFormData] = useState(intialForm);
  const [validated, setValidated] = useState(false);
  const [countryList, setcountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [provinceList, setprovinceList] = useState([]);
  const [superCityList, setSuperCityList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedSuperCity, setSelectedSuperCity] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCode, setSelectedCode] = useState(null);
  const [openAddOption, setOpenAddOption] = useState(true);
  const [currentPostalCode, setcurrentPostalCode] = useState("");
  const [isPostalCodeValid, setIsPostalCodeValid] = useState(true);
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [isLocationLenValid, setIsLocationLenValid] = useState(true);

  useEffect(() => {
    fetchCountry();
  }, []);

  async function fetchCountry() {
    let country = await getCountryList();
    if (country?.data) {
      setcountryList(country.data);
    }
  }

  const handleChangeCountry = async (e) => {
    setSelectedCountry(e.target.value)

    setFormData({
      ...formData,
      ["countryId"]: e.target.value,
      ["codes"]: []
    });
    let province = await getProvinceByCountryId(e.target.value)
    console.log({ province });
    setprovinceList(province?.data)
    setSelectedProvince(null)
    setSelectedSuperCity(null)
    setSelectedCity(null)
    setOpenAddOption(true)
  };

  const handleChangeProvince = async (e) => {
    setSelectedProvince(e.target.value)

    setFormData({
      ...formData,
      ["provinceId"]: e.target.value
    });
    let superCity = await getSuperCityByProvinceId(e.target.value)
    console.log({ superCity });
    setSuperCityList(superCity?.data)
    setCityList(superCity?.data)
    setSelectedSuperCity(null)
    setSelectedCity(null)
  };

  const handleChangeSuperCity = async (e) => {
    setSelectedSuperCity(e.target.value)
    setFormData({
      ...formData,
      ["supercityId"]: e.target.value
    });
    let cities = await getCityByProvinceId(e.target.value)
    console.log({ cities });
    setCityList(cities?.data)
    setSelectedCity(null)
  };

  const handleChangeCity = (e) => {
    setSelectedCity(e.target.value)
    setFormData({
      ...formData,
      ["cityId"]: e.target.value
    });
  };

  const handleChange = (e) => {
    const inputText = e.target.value;
    const postalLength = selectedCountry === '2' ? 5 : 6;
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;
    if (!selectedCountry) {
      setError('Please select a country');
      return
    }
    else if (inputText.length > 0 && !alphanumericRegex.test(inputText)) {
      setError('Input must contain only alphanumeric characters.');
      return;
    } else if ((inputText.length > postalLength)) {
      // setError(`Postal code must be exactly ${postalLength} letters.`);
      setError('');
      return;
    }
    else {
      setError('');
    }
    const postalCode = e.target.value;
    setcurrentPostalCode(postalCode)
    if (postalCode.trim("") !== "")
      setIsPostalCodeValid(true)
    // setSelectedCode(inputText.toUpperCase())
    // const codes = [];
    // codes.push(inputText.toUpperCase())
    // setFormData({
    //   ...formData,
    //   ["codes"]: codes
    // });
  };

  const categorizedPostalCodeList = (postalCodes) => {
    return postalCodes.reduce((acc, postalCode) => {
      const { cityId } = postalCode;
      if (!acc[cityId]) {
        acc[cityId] = [];
      }
      acc[cityId].push(postalCode);
      return acc;
    }, {});
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Validate the form
    const form = event.currentTarget;
    let isValidated = true;
    if (formData.codes.length === 0 || openAddOption) {
      setIsLocationLenValid(false)
      isValidated = false;
    } else {
      setIsLocationLenValid(true)
    }
    if (!isValidated) {
      setValidated(true);
      return;
    }
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    console.log(formData);
    try {
      dispatch(START_LOADING());
      await addNewPostalCodes(formData);
      dispatch(CLEAR_POSTAL_CODES());
      const categorizedPostalCodes = categorizedPostalCodeList(postalCodeList);

      const disableSuperPostalCodesId = Object.keys(categorizedPostalCodes).map(async (cityId) => {
        dispatch(START_LOADING());
        let postalCodes = await getPostalCodeByCityId(cityId);
        const addIsActive = postalCodes.data.map(postalCode => {
          return { ...postalCode, isChecked: false }
        })
        dispatch(UPDATE_POSTAL_CODE(addIsActive));
        dispatch(STOP_LOADING());
        return {
          cityId,
        }
      })
      isAdded(true);
    } catch (error) {

    } finally {
      dispatch(STOP_LOADING());
    }
  };

  const handleReset = (e) => {
    setFormData(intialForm);
    setSelectedCode('')
    setError('');
    setSelectedCountry(null);
    setSelectedProvince(null);
    setSelectedSuperCity(null);
    setSelectedCity(null);
    setOpenAddOption(true)
    setcurrentPostalCode("")
    setTimeout(() => {
      countryRef.current.selectedIndex = 0;
      provinceRef.current.selectedIndex = 0;
      superCityRef.current.selectedIndex = 0;
      cityRef.current.selectedIndex = 0;
    }, 0)
    setValidated(false); //
    setIsLocationLenValid(true)
  };
  const savePostalCode = () => {
    const form = document.getElementById("yourForm");
    if (currentPostalCode.trim("") !== "")
      setIsPostalCodeValid(true)
    else
      setIsPostalCodeValid(false)
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }
    if (currentPostalCode === "") {
      alert("Please enter province")
      return
    } else {
      setFormData({
        ...formData,
        codes: [...formData.codes, currentPostalCode],
      });
      setOpenAddOption(false);
      setcurrentPostalCode("")
      setIsLocationLenValid(true)
    }
  };

  const handleRemove = (index) => {
    const updatedOptions = [...formData.codes];
    updatedOptions.splice(index, 1);
    setFormData({
      ...formData,
      codes: updatedOptions,
    });
    if (updatedOptions.length === 0)
      setOpenAddOption(true)
  };
  const handleAdd = () => {
    if (formData.codes.length > 0)
      setOpenAddOption(!openAddOption)
  };
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit} id="yourForm">
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Country<FormLabelStar /></Form.Label>
          <Form.Select
            size="sm"
            aria-label=" Select Country"
            name="country"
            onChange={handleChangeCountry}
            value={selectedCountry || ''}
            required
            ref={countryRef}
          >
            <option value="" disabled>Select</option>
            {countryList?.map((c) => (
              <option value={c.id}
                key={c.id}
              >{c.countryName}</option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please select a country.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Province<FormLabelStar /></Form.Label>
          <Form.Select
            size="sm"
            aria-label=" Select Province"
            name="province"
            onChange={handleChangeProvince}
            // onChange={filterServices}
            value={selectedProvince || ''}
            required
            ref={provinceRef}
          >
            <option value="" disabled>Select</option>
            {provinceList?.map((cat) => (
              <option value={cat.id}
                key={cat.id}
              >{cat.provinceName}</option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please select a province.
          </Form.Control.Feedback>
        </Form.Group>
        {/* <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Super City<FormLabelStar /></Form.Label>
          <Form.Select
            size="sm"
            aria-label=" Select Super City"
            name="supercity"
            onChange={handleChangeSuperCity}
            // onChange={filterServices}
            value={selectedSuperCity || ''}
            required
            ref={superCityRef}
          >
            <option value="" disabled>Select</option>
            {superCityList?.map((cat) => (
              <option value={cat.id}
                key={cat.id}
              >{cat.superCityName}</option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please select a super city.
          </Form.Control.Feedback>
        </Form.Group> */}
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>City<FormLabelStar /></Form.Label>
          <Form.Select
            size="sm"
            aria-label=" Select Province"
            name="serviceCategoryId"
            onChange={handleChangeCity}
            // onChange={filterServices}
            value={selectedCity || ''}
            required
            ref={cityRef}
          >
            <option value="" disabled>Select</option>
            {cityList?.map((cat) => (
              <option value={cat.id}
                key={cat.id}
              >{cat.cityName}</option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please select a city.
          </Form.Control.Feedback>
        </Form.Group>
        {/* <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Postal Code</Form.Label>
          <Form.Control
            required
            type="text"
            name="name"
            value={selectedCode}
            onChange={handleChange}
            placeholder="Enter postal code"
            className={`form-control ${error ? 'is-invalid' : ''}`}
          />
          {
            !error && <Form.Control.Feedback type="invalid">
              Please enter the postal code.
            </Form.Control.Feedback>
          }
          {error && <Form.Control.Feedback type="invalid">
            {error}
          </Form.Control.Feedback>
          }
        </Form.Group> */}
        <Form.Group className="mb-3" controlId="formBasicPassword"
        >
          <Form.Label >Postal Code<FormLabelStar /></Form.Label>{" "}
          <a
            className="link-btn"
            style={{ marginLeft: "20px", marginBottom: "15px" }}
            onClick={handleAdd}
          >
            Add
          </a>
          {formData.codes.map((o, index) => (
            <div className="d-flex justify-content-between mb-3" key={index}>
              <Form.Control
                size="sm"
                aria-label=" Select Category"
                name="option"
                style={{ marginRight: "15px" }}
                value={o}
                disabled
              />
              <Button
                className="link-btn"
                onClick={() => handleRemove(index)}
                size="sm"
              >
                Remove
              </Button>
            </div>
          ))}
        </Form.Group>
        <Form.Group className="" controlId="formBasicPassword"
        >
          {openAddOption && (
            <div className="save d-flex">
              <Form.Control
                size="sm"
                aria-label="add province"
                name="province"
                style={{ marginRight: "15px" }}
                onChange={handleChange}
                value={currentPostalCode}
                required
              />

              <Button onClick={savePostalCode} size="sm" style={{ width: "73px", marginLeft: "15px" }}>{" "}Save  </Button>
            </div>
          )}
          {!isPostalCodeValid && <div className="province-name-validate">
            Please enter a postal code.
          </div>}
          {error && <div className="province-name-validate" >
            {error}
          </div>}
          {!isLocationLenValid && <div className="province-name-validate">
            Please save city.
          </div>}
        </Form.Group>
        <div className="d-flex justify-content-between m-2">
          <Button variant="primary" type="submit" size="sm">
            Submit
          </Button>
          <Button variant="secondary" type="reset" size="sm"
            onClick={() => { handleReset() }}
          >
            Reset
          </Button>
        </div>
      </Form>
    </>
  );
}
