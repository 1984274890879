import { useEffect, useState } from "react";
import {
  getServiceByCategoryId,
  getServiceCategory,
  getServices,
  DeleteService,
  updateServiceStatusAPI
} from "../../api/adminApi";
import { Button, Container, Form } from "react-bootstrap";
import ReusableTable from "../../components/core/ReusableTable";
import { ServiceColumns } from "../../metaData/ColumnList";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SAVE_CATEGORIES } from "../../redux/Slice/adminSlice";
import ShowModal from "../../components/core/Modal";
import AddNewService from "../../components/Admin/serviceManagement/AddNewService";
import { START_LOADING, STOP_LOADING } from "../../redux/Slice/loaderSlice";
import { useNavigate } from "react-router-dom";

export default function Services() {
  console.log("service page=====>")
  const dispatch = useDispatch();
  const [serviceList, setServiceList] = useState();
  const [displayedServiceList, setDisplayedServiceList] = useState();
  const [serviceCategoryList, setserviceCategoryList] = useState();
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [actions, setActions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setEditModal] = useState(false);
  const [selectedService, setSelectedServcie] = useState();
  const navigate = useNavigate();

  function AddActions() {
    setActions([
      {
        label: "view",
        clickAction: viewServiceDetails,
        variant: "success",
      },
      {
        label: "edit",
        clickAction: openEditModal,
      },
      {
        label: "delete",
        variant: "danger",
        clickAction: openDeleteModal,
      },
    ]);
  }

  const viewServiceDetails = (item) => {
    navigate(`/admin/services/serviceDetails/${item.id}`);
  };

  const { id } = useParams();
  useEffect(() => {
    fetchService();
  }, []);
  useEffect(() => {
    if (serviceList) {
      fetchCategory();
    }
  }, [serviceList]);

  async function fetchService() {
    let serviceListfromAPI;

    serviceListfromAPI = await getServices();

    setServiceList(serviceListfromAPI.data);
    setDisplayedServiceList(serviceListfromAPI.data);
  }
  async function fetchCategory() {
    dispatch(START_LOADING());
    let serviceCategory = await getServiceCategory();
    dispatch(STOP_LOADING());

    setserviceCategoryList(serviceCategory.data);
    dispatch(SAVE_CATEGORIES(serviceCategory.data));
    if (Number(id)) {
      setSelectedCategory(id);
      let filteredService = serviceList?.filter(
        (s) => s.serviceCategoryId == id
      );
      console.log(filteredService);
      setDisplayedServiceList(filteredService);
    }

    AddActions();
  }
  //Open Modal to add new Service
  const newServiceAdded = () => {
    setShowModal(false);
    setEditModal(false);
    fetchService();
  };
  //show service based on category selected
  function filterServices(event) {
    const val = event.target.value;
    setSelectedCategory(val);
    if (Number(val)) {
      let filteredService = serviceList.filter(
        (s) => s.serviceCategoryId == val
      );

      setDisplayedServiceList(filteredService);
    } else {
      setDisplayedServiceList(serviceList);
    }
  }
  const viewCategory = (item) => { };
  //Open Confirmation modal for delete service
  const openDeleteModal = async (item) => {
    setShowDeleteModal(true);
    setSelectedServcie(item);
  };

  const openEditModal = async (item) => {
    console.log("item click======>",item)
    setEditModal(true);
    setSelectedServcie(item);
  };

  const deleteService = async () => {
    console.log({ selectedService });
    await DeleteService(selectedService.id);
    setShowDeleteModal(false);
    fetchService();
  };

  async function updateServiceStatus(user) {
    console.log({ user });
    dispatch(START_LOADING());
    await updateServiceStatusAPI(user?.id,user?.serviceCategoryId ,!user.isActive)
    // dispatch(STOP_LOADING());
    await fetchService();
  }
  return (
    <Container className="bg-white" style={{ padding: "1rem" }}>
      <Container className="d-flex justify-content-between mb-2 bg-white p-2">
        <h3 className="heading"> Service List</h3>
        <Button onClick={() => setShowModal(true)}>Add New Service</Button>
      </Container>
      <Container
        className="d-flex mb-2 bg-white p-3 "
        style={{ borderRadius: "5px" }}
      >
        <label className="heading"> Select Category</label>
        <div style={{ marginLeft: "10px" }}>
          <Form.Select
            size="sm"
            aria-label=" Select Category"
            onChange={filterServices}
            value={selectedCategory}
          >
            <option value={0}>All</option>
            {serviceCategoryList?.map((cat) => (
              <option value={cat.id}>{cat.name}</option>
            ))}
          </Form.Select>
        </div>
      </Container>
      <hr></hr>
      <Container className="service-list-table" style={{ padding: "0" }}>
        {/* {displayedServiceList?.length == 0 ? (
          <>
            <div className="d-flex justify-content-center">
              {" "}
              No Service to Show
            </div>
          </>
        ) : ( */}
        <ReusableTable
          columns={ServiceColumns}
          data={displayedServiceList}
          userStatusChange={updateServiceStatus}
          actions={actions}
        ></ReusableTable>
        {/* )} */}
      </Container>
      <ShowModal
        modalheading={showEditModal ? "Edit Service" : "Add New Service"}
        show={showModal || showEditModal}
        classname={"add-service-modal"}
        body={<AddNewService isAdded={newServiceAdded} service={showEditModal ? selectedService : undefined}></AddNewService>}
        handleClose={() => {
          setShowModal(false)
          setEditModal(false)
        }}
      ></ShowModal>
      <ShowModal
        modalheading="Delete Service"
        show={showDeleteModal}
        extraData={selectedService}
        classname={"add-service-modal"}
        body="Are you sure you want to delete this service"
        handleClose={() => setShowDeleteModal(false)}
        showFooter={true}
        Submit={deleteService}
      ></ShowModal>
    </Container>
  );
}
