import { async } from "q";
import { get, post, remove, put, getV1, postV1, removeV1, putV1, patch, downloadXLS } from "./api";

const headers = {
  "Content-Type": "application/json",
}

export const setHeader = async () => {
  const token = localStorage.getItem('token');
  if (token)
    headers.Authorization = `Bearer ${token}`;
  // console.log({ headers });
  return headers;
}

export const fetchDashboardSummary = async () => {
  await setHeader();
  return await get("admin/dashboardSummary", headers);
};
export const getTasker = async (currentPage, pageSize, searchQuery) => {
  await setHeader();
  return await get(`admin/tasker?count=${pageSize}&pageNo=${currentPage}${searchQuery}`, headers);
};
export const getCustomer = async (currentPage, pageSize, searchQuery) => {
  currentPage = currentPage <= 0 ? 0 : currentPage
  await setHeader();
  return await get(
    `admin/customer?count=${pageSize}&pageNo=${currentPage}${searchQuery}`,
    headers
  );
};
export const getServiceByCategoryId = async (id) => {
  await setHeader();
  return await get(`service?id=${id}&count=12&pageNo=0`, headers);
};
export const getServices = async (id) => {
  await setHeader();
  return await get(`services?count=100&pageNo=0`, headers);
};
export const getServiceCategory = async (data) => {
  await setHeader();
  return await get("serviceCategory", headers);
};

export const AddNewServiceCategory = async (data) => {
  await setHeader();
  return await post("serviceCategory", data, headers);
};

export const EditNewServiceCategory = async (data) => {
  await setHeader();
  return await put("serviceCategory", data, headers);
};

export const CreateNewService = async (data) => {
  await setHeader();
  return await post("service", data, headers);
};

export const EditService = async (data) => {
  await setHeader();
  return await put("service", data, headers);
};

export const uploadFile = async (formData) => {
  await setHeader();
  return await post("upload-file", formData, {
    "Content-Type": "multipart/form-data",
    "Authorization": headers.Authorization
  });
};
export const DeleteServiceCategory = async (id) => {
  await setHeader();
  return await remove(`serviceCategory?id=${id}`, headers);
};

export const DeleteService = async (id) => {
  await setHeader();
  return await remove(`service?id=${id}`, headers);
};

export const getServiceById = async (id) => {
  await setHeader();
  return await get(`service?id=${id}`, headers);
};

export const getServiceDetailsById = async (id) => {
  await setHeader();
  return await getV1(`user/serviceDetails?id=${id}`, headers);
};

export const CreateNewQuestion = async (data) => {
  await setHeader();
  return await post("question", data, headers);
};

export const EditQuestion = async (data) => {
  await setHeader();
  return await put("question", data, headers);
};

export const DeleteQuestion = async (id) => {
  await setHeader();
  return await remove(`question?id=${id}`, headers);
};

export const getServicePricesById = async (id) => {
  await setHeader();
  return await get(`admin/servicePrice?serviceId=${id}`, headers);
};

export const updateServicePrice = async (data) => {
  await setHeader();
  return await put("admin/servicePrice", data, headers);
};

export const getSuppliesByServiceId = async (id) => {
  await setHeader();
  return await get(`supply?id=${id}`, headers);
};

export const editSupply = async (data) => {
  await setHeader();
  return await put("supply", data, headers);
};

export const addNewSupply = async (data) => {
  await setHeader();
  return await post("supply", data, headers);
};

export const deleteSupplyById = async (id) => {
  await setHeader();
  return await remove(`supply?id=${id}`, headers);
};

export const getHelperTool = async (data) => {
  await setHeader();
  return await getV1("admin/tool", headers, 'v1');
};

export const addNewHelperTool = async (data) => {
  await setHeader();
  return await postV1(`admin/tool?name=${data.name}`, {}, headers);
};

export const editHelperTool = async (data) => {
  await setHeader();
  return await putV1("admin/tool", data, headers);
};

export const deleteHelperTool = async (id) => {
  await setHeader();
  return await removeV1(`admin/tool?id=${id}`, headers);
};

export const getHelperLanguage = async (data) => {
  await setHeader();
  return await getV1("admin/language", headers, 'v1');
};

export const addNewHelperLanguage = async (data) => {
  await setHeader();
  return await postV1(`admin/language`, data, headers);
};

export const editHelperLanguage = async (data) => {
  await setHeader();
  return await putV1("admin/language", data, headers);
};

export const deleteHelperLanguage = async (id) => {
  await setHeader();
  return await removeV1(`admin/language?id=${id}`, headers);
};

export const getHelperVehicle = async (data) => {
  await setHeader();
  return await getV1("admin/vehicle", headers, 'v1');
};

export const addNewHelperVehicle = async (data) => {
  await setHeader();
  return await postV1(`admin/vehicle`, data, headers);
};

export const editHelperVehicle = async (data) => {
  await setHeader();
  return await putV1("admin/vehicle", data, headers);
};

export const deleteHelperVehicle = async (id) => {
  await setHeader();
  return await removeV1(`admin/vehicle?id=${id}`, headers);
};

export const getHelperQuickFact = async (data) => {
  await setHeader();
  return await getV1("admin/fact", headers, 'v1');
};

export const addNewHelperQuickFact = async (data) => {
  await setHeader();
  return await postV1(`admin/fact`, data, headers);
};

export const editHelperQuickFact = async (data) => {
  await setHeader();
  return await putV1("admin/fact", data, headers);
};

export const deleteHelperQuickFact = async (id) => {
  await setHeader();
  return await removeV1(`admin/fact?id=${id}`, headers);
};

export const getTasks = async (page = 0, count = 10, filterQuery) => {
  await setHeader();
  return await getV1(`/admin/tasks?count=${count}&page=${page}${filterQuery}`, headers);
};

export const getTaskAnswers = async (id) => {
  await setHeader();
  return await getV1(`/admin/taskAnswers?taskId=${id}`, headers);
};

export const getTaskItems = async (id) => {
  await setHeader();
  return await getV1(`/admin/items?taskId=${id}`, headers);
};

export const updateStatus = async (id, status) => {
  await setHeader();
  return await patch(
    `admin/userStatus?id=${id}&status=${status}`,
    {},
    headers
  );
};

export const updateUserDetails = async ({ userId, firstName, lastName,email,mobileNumber,postalCode }) => {
  await setHeader();
  return await putV1(`admin/updateUserDetails?userId=${userId}&firstName=${firstName}&lastName=${lastName}&email=${email}&mobileNumber=${mobileNumber}&postalCode=${postalCode}`, {}, headers);
};

export const getHelperProfile = async (id) => {
  await setHeader();
  return await getV1(`admin/helperProfile?userId=${id}`, headers);
};

export const getHelperSkills = async (id) => {
  await setHeader();
  return await getV1(`admin/getSkills?userId=${id}`, headers);
};

export const setHelperSkillsInformation = async (userId,skillId, skillStatus) => {
  console.log("skillStatus=======>",skillStatus)
  await setHeader();
  return await putV1(
    `admin/updateSkill?userId=${userId}&skillId=${skillId}&skillStatus=${skillStatus}`,
    {},
    headers
  );
};


export const getHelperKYCDetails = async (id) => {
  await setHeader();
  return await getV1(`helper/getKycDetails?userId=${id}`, headers);
};

export const getHelperRatings = async ({ page = 0, count = 10, id }) => {
  await setHeader();
  return await getV1(`admin/getRating?count=${count}&page=${page}&helperId=${id}`, headers);
};

export const getUserPaymentDetails = async (page = 0, count = 10, filterQuery = '') => {
  await setHeader();
  return await getV1(`/admin/userPaymentDetails?count=${count}&page=${page}${filterQuery}`, headers);
};

export const getPayoutList = async (page = 0, count = 10, filterQuery = '') => {
  await setHeader();
  return await getV1(`/admin/payoutDetails?count=${count}&page=${page}${filterQuery}`, headers);
};

export const getAllSkillTradeList = async (page = 0, count = 10, filterQuery) => {
  await setHeader();
  return await getV1(`/admin/getAllTaskerSkills?count=${count}&page=${page}${filterQuery}`, headers);
};

export const skillTradeApproveReject = async ({ updateQuery }) => {
  await setHeader();
  return await putV1(`/admin/skillTrade?${updateQuery}`, {}, headers);
};

export const downloadHelperList = async () => {
  await setHeader();
  return await downloadXLS(`admin/helperExcelData`, headers);
};

export const downloadCustomerList = async () => {
  await setHeader();
  return await downloadXLS(`admin/customerExcelData`, headers);
};

export const downloadTaskList = async () => {
  await setHeader();
  return await downloadXLS(`admin/tasksExcelData`, headers);
};

export const updateServiceStatusAPI = async (serviceId,serviceCategoryId, status) => {
  await setHeader();
  return await putV1(
    // api/v1/admin/updateServiceStatus
    `admin/updateServiceStatus?serviceId=${serviceId}&serviceCategoryId=${serviceCategoryId}&serviceStatus=${status}`,
    {},
    headers
  );
};