import { useState, useEffect, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getCountryList,
  addNewCities,
  getProvinceByCountryId,
  getSuperCityByProvinceId,
  // getSuperCityByProvinceId,
  getCityByProvinceId
} from "../../../api/locationApi";
import { START_LOADING, STOP_LOADING } from "../../../redux/Slice/loaderSlice";
import { CLEAR_CITY, UPDATE_CITY } from "../../../redux/Slice/locationSlice";
import FormLabelStar from "../../core/FormLabelStar";
import { validationLength } from "../../../metaData/validationLength"

const intialForm = {
  "id": 0,
  "cities": [
  ],
  "countryId": null,
  "provinceId": null,
  // "supercityId": null
};

export default function AddNewSuperCity({ isAdded }) {
  const cityList = useSelector((state) => state.location).filteredCity;
  const countryRef = useRef(null);
  const provinceRef = useRef(null);
  const superCityRef = useRef(null);
  const [formData, setFormData] = useState(intialForm);
  const [validated, setValidated] = useState(false);
  const [countryList, setcountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [provinceList, setprovinceList] = useState([]);
  const [superCityList, setSuperCityList] = useState([]);
  const [superCity, setSuperCity] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedSuperCity, setSelectedSuperCity] = useState(null);
  const [openAddOption, setOpenAddOption] = useState(true);
  const [currentCity, setcurrentCity] = useState("");
  const [isCityValid, setIsCityValid] = useState(true);
  const dispatch = useDispatch();
  const [isLocationLenValid, setIsLocationLenValid] = useState(true);

  useEffect(() => {
    fetchCountry();
  }, []);

  async function fetchCountry() {
    let country = await getCountryList();
    if (country?.data) {
      setcountryList(country.data);
    }
  }

  const handleChangeCountry = async (e) => {
    setSelectedCountry(e.target.value)

    setFormData({
      ...formData,
      ["countryId"]: e.target.value
    });
    let province = await getProvinceByCountryId(e.target.value)
    console.log({ province });
    setprovinceList(province?.data)
    setSelectedProvince(null)
    setSelectedSuperCity(null)
  };

  const handleChangeProvince = async (e) => {
    setSelectedProvince(e.target.value)

    setFormData({
      ...formData,
      ["provinceId"]: e.target.value
    });
    let superCity = await getSuperCityByProvinceId(e.target.value)
    console.log({ superCity });
    setSuperCityList(superCity?.data)
    setSuperCity(superCity?.data)
    setSelectedSuperCity(null)
  };

  const handleChangeSuperCity = (e) => {
    setSelectedSuperCity(e.target.value)
    setFormData({
      ...formData,
      ["supercityId"]: e.target.value
    });
  };

  const handleChange = (e) => {
    const city = e.target.value;
    setcurrentCity(city)
    if (city.trim("") !== "")
      setIsCityValid(true)
    // setSuperCity(e.target.value)
    // const city = [];
    // city.push(e.target.value)
    // setFormData({
    //   ...formData,
    //   ["cities"]: city
    // });
  };
  const categorizedCityList = (superCities) => {
    return superCities.reduce((acc, superCity) => {
      const { superCityId } = superCity;
      if (!acc[superCityId]) {
        acc[superCityId] = [];
      }
      acc[superCityId].push(superCity);
      return acc;
    }, {});
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Validate the form
    const form = event.currentTarget;
    let isValidated = true;
    if (formData.cities.length === 0 || openAddOption) {
      setIsLocationLenValid(false)
      isValidated = false;
    } else {
      setIsLocationLenValid(true)
    }
    if (!isValidated) {
      setValidated(true);
      return;
    }
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    console.log("formData new logs",formData);
    try {
      dispatch(START_LOADING());
      await addNewCities(formData);
      // dispatch(CLEAR_CITY())
      const categorizedSuperCities = categorizedCityList(cityList);
      const disableSuperCitiesId = Object.keys(categorizedSuperCities).map(async (superCityId) => {
        dispatch(START_LOADING());
        let cities = await getCityByProvinceId(superCityId);
        cities = cities.data.map((city) => { return { ...city, isChecked: false } })
        dispatch(UPDATE_CITY(cities));
        dispatch(STOP_LOADING());
        return {
          supercityId: superCityId,
        }
      })
      isAdded(true);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(STOP_LOADING());
    }
  };

  const handleReset = (e) => {
    setFormData(intialForm);
    setSelectedCountry("");
    setOpenAddOption(true)
    setcurrentCity("")
    setSelectedProvince("")
    setSelectedSuperCity("")
    setTimeout(() => {
      countryRef.current.selectedIndex = 0;
      provinceRef.current.selectedIndex = 0;
      superCityRef.current.selectedIndex = 0;

    }, 0)
    setValidated(false); //
    setIsLocationLenValid(true)
  };

  const saveCity = () => {
    const form = document.getElementById("yourForm");
    if (currentCity.trim("") !== "")
      setIsCityValid(true)
    else
      setIsCityValid(false)
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }
    if (currentCity === "") {
      alert("Please enter province")
      return
    } else {
      setFormData({
        ...formData,
        cities: [...formData.cities, currentCity],
      });
      setOpenAddOption(false);
      setcurrentCity("")
      setIsLocationLenValid(true)
    }
  };

  const handleRemove = (index) => {
    const updatedOptions = [...formData.cities];
    updatedOptions.splice(index, 1);
    setFormData({
      ...formData,
      cities: updatedOptions,
    });
    if (updatedOptions.length === 0)
      setOpenAddOption(true)
  };
  const handleAdd = () => {
    if (formData.cities.length > 0)
      setOpenAddOption(!openAddOption)
  };
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit} id="yourForm">
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Country<FormLabelStar /></Form.Label>
          <Form.Select
            size="sm"
            aria-label=" Select Country"
            name="country"
            onChange={handleChangeCountry}
            value={selectedCountry || ''}
            required
            ref={countryRef}
          >
            <option value="" disabled>Select</option>
            {countryList?.map((c) => (
              <option value={c.id}
                key={c.id}
              >{c.countryName}</option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please select a country.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Province<FormLabelStar /></Form.Label>
          <Form.Select
            size="sm"
            aria-label=" Select Province"
            name="province"
            onChange={handleChangeProvince}
            // onChange={filterServices}
            value={selectedProvince || ''}
            required
            ref={provinceRef}
          >
            <option value="" disabled>Select</option>
            {provinceList?.map((cat) => (
              <option value={cat.id}
                key={cat.id}
              >{cat.provinceName}</option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please select a province.
          </Form.Control.Feedback>
        </Form.Group>
        {/* <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Super City<FormLabelStar /></Form.Label>
          <Form.Select
            size="sm"
            aria-label=" Select Super City"
            name="supercity"
            onChange={handleChangeSuperCity}
            // onChange={filterServices}
            value={selectedSuperCity || ''}
            required
            ref={superCityRef}
          >
            <option value="" disabled>Select</option>
            {superCityList?.map((cat) => (
              <option value={cat.id}
                key={cat.id}
              >{cat.superCityName}</option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please select a super city.
          </Form.Control.Feedback>
        </Form.Group> */}
        {/* <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>City name</Form.Label>
          <Form.Control
            required
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="City name"
          />
        </Form.Group> */}
        <Form.Group className="mb-3" controlId="formBasicPassword"
        >
          <Form.Label >City<FormLabelStar /></Form.Label>{" "}
          <a
            className="link-btn"
            style={{ marginLeft: "20px", marginBottom: "15px" }}
            onClick={handleAdd}
          >
            Add
          </a>
          {formData.cities.map((o, index) => (
            <div className="d-flex justify-content-between mb-3" key={index}>
              <Form.Control
                size="sm"
                aria-label=" Select Category"
                name="option"
                style={{ marginRight: "15px" }}
                value={o}
                disabled
              />
              <Button
                className="link-btn"
                onClick={() => handleRemove(index)}
                size="sm"
              >
                Remove
              </Button>
            </div>
          ))}
        </Form.Group>
        <Form.Group className="" controlId="formBasicPassword"
        >
          {openAddOption && (
            <div className="save d-flex">
              <Form.Control
                size="sm"
                aria-label="add province"
                name="province"
                style={{ marginRight: "15px" }}
                onChange={handleChange}
                value={currentCity}
                required
                maxLength={validationLength.location.name.maxLength}
              />

              <Button onClick={saveCity} size="sm" style={{ width: "73px", marginLeft: "15px" }}>{" "}Save  </Button>
            </div>
          )}
          {currentCity.length > validationLength.location.name.maxLength - 1 && (
            <div className="province-name-validate">{validationLength.message(validationLength.location.name.maxLength)}</div>
          )}
          {!isCityValid && <div className="province-name-validate">
            Please enter a city name.
          </div>}
          {!isLocationLenValid && <div className="province-name-validate">
            Please save city.
          </div>}
        </Form.Group>
        <div className="d-flex justify-content-between m-2">
          <Button variant="primary" type="submit" size="sm">
            Submit
          </Button>
          <Button variant="secondary" type="reset" size="sm"
            onClick={() => { handleReset() }}
          >
            Reset
          </Button>
        </div>
      </Form>
    </>
  );
}
